const markUpSchema = {
  '@context': 'http://schema.org',
  '@type': 'FinancialService',
  name: 'Credicuotas',
  description: 'Credicuotas es una fintech que se especializa en brindar préstamos personales online para los clientes, ya sea a través de su red de comercios adheridos, o en forma directa a través de Internet. Busca generar ofertas de crédito inmediatas, simples y de gran valor para sus clientes. Las soluciones de Credicuotas permiten evaluar automáticamente los perfiles de los clientes en cuanto a su capacidad de repago y determinar en segundos si pueden acceder a una oferta de crédito. De esta manera, otorgan la liquidación del préstamo en la cuenta bancaria del cliente en forma rápida y sencilla, en cualquier lado donde se encuentre. ¡Lo único que necesita es contar con acceso a Internet! Es parte del Grupo Bind, Banco Industrial SA, lo que les permite poder ofrecer nuestros productos a miles de clientes en Argentina. Su misión es: la democratización y el fácil acceso al crédito para fomentar la inclusión financiera.',
  url: 'https://www.credicuotas.com.ar/',
  sameAs: ['https://ar.linkedin.com/company/credicuotas', 'https://www.facebook.com/credicuotasarg', 'https://www.youtube.com/@credicuotas1745', 'https://www.instagram.com/credicuotas.ar/'],
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Arenales 707',
    addressLocality: 'Ciudad Autónoma de Buenos Aires',
    postalCode: 'C1061AAA',
    addressCountry: 'Argentina',
  },
};

export default markUpSchema;
