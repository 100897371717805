import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import {
  getEnv, ENVIRONMENT,
} from '../../api/env';
import markUpSchema from '../../constants/markUpSchema';

const HeaderTags = ({
  title, content, metaContent, scriptMarkUpSchema,
}) => {
  const ENV = getEnv(ENVIRONMENT);
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content={content} />
      {ENV !== 'UAT' && (<meta name="robots" content={metaContent} />)}
      { scriptMarkUpSchema && (<script type="application/ld+json">{JSON.stringify(markUpSchema)}</script>) }
    </Helmet>
  );
};

HeaderTags.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  metaContent: PropTypes.string,
  scriptMarkUpSchema: PropTypes.bool,
};

HeaderTags.defaultProps = {
  metaContent: 'index, follow',
  scriptMarkUpSchema: false,
};

export default HeaderTags;
