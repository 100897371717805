/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
import routes from '../navigation/routes';

const i18n = {
  credicuotas: 'Credicuotas',
  commerce: 'Comercios',
  faq: 'Preguntas frecuentes',

  // Format handlers
  invalidFormat: 'Formato Inválido',
  valueRequired: '${0} requerido',
  onlyNumbersAllowed: 'Solo ingresar números',
  maxLenght: 'Longitud maxima ${0}',

  // Header
  headerCommerce: 'Comercios',
  headerClient: 'Clientes',
  headerMenuHome: { title: 'Inicio', route: routes.home },
  headerMenuCard: { title: 'Tarjeta Credicuotas', route: routes.card },
  headerMenuUs: { title: 'Nosotros', route: routes.us },
  headerMenuBlog: { title: 'Blog', route: routes.blog },
  headerMenuPhysicalCommerce: { title: 'Comercios físicos', route: routes.physicalCommerce },
  // headerMenuBuyNowPayLater: { title: 'Tiendas online', route: routes.buyNowPayLater },
  headerMenuWt: { title: 'Pedí tu préstamo', route: routes.wt },
  headerMenuHelp: { title: 'Ayuda', route: routes.help },
  headerWhereToBuyTitle: 'Dónde comprar',

  // Metadata
  metaTitleHome: 'Prestamos Online | CrediCuotas',
  metaTitlePrepaidCard: 'Tarjeta de crédito prepaga Credicuotas',
  metaTitleBlog: 'Comunidad - Préstamos online | Credicuotas',
  metaTitleUserInformation: 'Información a usuarios financieros',
  metaTitleCancel: 'Cancelá tu préstamo',
  metaTitleWc: 'Comprá en comercios',
  metaDescriptionHome: 'Pedí tu préstamo online y pagalo en cuotas fijas! Te lo acreditamos en el mismo día. Ingresá para ver cuánto te prestamos. Credicuotas',
  metaDescriptionPrepaidCard: 'Llevate tu tarjeta prepaga de Credicuotas de manera gratis y al instante',
  metaDescriptionHelp: 'Acá vas a encontrar las respuestas a tus dudas sobre nuestros productos.',
  metaDescriptionCancel: 'Te explicamos como cancelar tu prestamo solicitado, paso a paso.',
  metaDescriptionUserInformation: 'Información y medios de contacto para el usuario financiero',
  metaDescriptionUs: 'Somos una compañía especialista en finanzas. Ofrecemos préstamos personales online, sin vueltas y sin letra chica.',
  metaDescriptionWc: 'Comprá lo que quieras y pagá en cuotas sin tarjeta de crédito en comercios adheridos.',
  metaDescriptionConsumerProtection: 'Información de utilidad para el consumidor.',
  metaDescriptionBlog: 'Novedades sobre el sector financiero',

  Motorcycle: {
    title: 'Préstamos para comprar tu moto',
    description: 'Obtené un préstamo para financiar la compra de tu próxima moto en cuotas fijas.',
    ctaBanner: '¡Pedilo ahora!',
    howToTitle: '¿Cómo saco un préstamo para comprar una moto?',
    howToSteps: [
      { description: 'Acercate a un comercio adherido', image: ['/images/step1.png', '/images/step1_mobile.png'] },
      { description: 'Brindale tus datos al vendedor', image: ['/images/step2.png', '/images/step2_mobile.png'] },
      { description: '¡Llevate tu moto!', image: ['/images/motorcycle/step3.png', '/images/motorcycle/step3_mobile.png'] },
    ],
    requirementsTitle: '¿Qué necesito para sacar el préstamo?',
    requirements: [
      { description: 'Ser mayor de 21 años y tener DNI argentino', image: ['/images/req_1.png', '/images/req_1_mobile.png'] },
      { description: 'Número de celular y mail particular', image: ['/images/req_2.png', '/images/req_2_mobile.png'] },
      { description: 'Debés tener tus ingresos en blanco', image: ['/images/req_3.png', '/images/req_3_mobile.png'] },
    ],
    brandsTitle: '¡Llevate la moto que quieras!',
    brandsDescription: 'Honda, Motomel, Corven, Gilera, Zanella, Mondial, Keller, Bajaj, <span style="color: #f2025d">y más! *</span>',
    chooseUsTitle: ['¿Por qué elegirnos para financiar la compra de tu moto?', '¿Por qué elegirnos?'],
    chooseUsMessages: [
      { title: 'Somos simples', description: 'Brindamos préstamos personales para la financiación de cualquier moto solo con minimos requisitos.' },
      { title: 'Financiamos en cuotas fijas', description: 'Otorgamos préstamos en cuotas fijas, significa que siempre pagas la misma cuota.' },
      { title: 'Comprá la moto que quieras', description: 'Contamos con más de 600 comercios y concesionarias a lo largo de todo el país, que trabajan con todas las marcas de motos del mercado.' },
    ],
    faqs: [
      {
        question: '¿Cuánto demora tener el préstamo para mi moto?',
        answer: `Solicitá tu crédito y vas a tener el dinero para tu moto disponible en instantes.
        <br /><br/>
        El proceso en Credicuotas es rápido y sencillo. Luego de una breve verificación crediticia, vas a tener el dinero disponible rápidamente para que puedas comprar tu moto.`,
      },
      {
        question: '¿Cómo es el proceso para tomar un crédito para motos?',
        answer: `Es un proceso simple que comienza en vos seleccionando el monto a solicitar y el plazo del préstamo. Luego de eso, solo con una foto de frente y dorso de tu DNI original y una selfie es suficiente.
        <br /><br/>
        La verificación crediticia es muy breve y una vez aprobado, tendrás tu dinero en instantes.`,
      },
      {
        question: '¿Los créditos para motos de Credicuotas son créditos prendarios?',
        answer: 'Eso lo elegís vos. Depende el comercio o concesionario de moto  podes tomar el préstamo personal o el préstamo prendario de Credicuotas.',
      },
      {
        question: '¿En qué comercios o concesionarios puedo comprar la moto?',
        answer: 'Podes comprar una moto con financiación de Credicuotas en cualquier comercio o concesionario que este adherido a la red de comercios que trabajan con nuestros préstamos a lo largo de todo el país.',
      },
    ],
    footer: '* Las siguientes marcas son financiadas a clientes finales en la red de comercios adheridos a Credicuotas Consumo S.A. Su difusión es únicamente con fines publicitarios, informativos y de buena fé para los consumidores. Credicuotas Consumo S.A. declara no ser propietaria, responsable de las marcas que se exponen y no constituye uso a título de la marca. Las marcas Honda, Bajab, Zanella, Motomel, Keller, Corven, son propiedad registrada siendo sus propietarios titulares de cada marca.',
  },

  faqTitle: 'Preguntas frecuentes',
  faqMore: ['¿Tenés más dudas? ', 'Ingresa acá'],

  ElectroLanding: {
    title: 'Préstamos para comprar tu electro',
    description: 'Obtené un préstamo y financiá la compra de tu electrodoméstico en cuotas fijas. Heladeras, aires acondicionados, lavarropas y mucho más!',
    ctaBanner: '¡Pedilo ahora!',
    howToTitle: '¿Cómo saco un préstamo para comprar un electro?',
    howToSteps: [
      { description: 'Acercate a un comercio adherido', image: ['/images/step1.png', '/images/step1_mobile.png'] },
      { description: 'Brindale tus datos al vendedor', image: ['/images/step2.png', '/images/step2_mobile.png'] },
      { description: '¡Llevate tu electro!', image: ['/images/electro/step3.png', '/images/electro/step3_mobile.png'] },
    ],
    requirementsTitle: '¿Qué necesito para sacar el préstamo?',
    requirements: [
      { description: 'Ser mayor de 21 años y tener DNI argentino', image: ['/images/req_1.png', '/images/req_1_mobile.png'] },
      { description: 'Número de celular y mail particular', image: ['/images/req_2.png', '/images/req_2_mobile.png'] },
      { description: 'Debés tener tus ingresos en blanco', image: ['/images/req_3.png', '/images/req_3_mobile.png'] },
    ],
    brandsTitle: '¡Llevate el electrodoméstico que quieras!',
    brandsDescription: 'LG, Samsung, Whirlpool, BGH, Philco, Atma, Orbis, Marshall, <span style="color: #f2025d">y muchas más! *</span>',
    chooseUsTitle: ['¿Por qué elegirnos para financiar la compra de tu electro?', '¿Por qué elegirnos?'],
    chooseUsMessages: [
      { title: 'Somos simples', description: 'Brindamos préstamos personales para que puedas comprarte los electrodomésticos que quieras, sólo con mínimos requisitos.' },
      { title: 'Financiamos en cuotas fijas', description: 'Otorgamos préstamos en cuotas fijas, significa que siempre pagas la misma cuota a lo largo de todo el préstamo.' },
      { title: 'Comprá los electrodomésticos que quieras', description: 'Contamos con más de 600 comercios adheridos a lo largo del pais, que trabajan con todas las marcas de electrodomésticos como BGH, Whirlpool, Longvie, LG, ATMA, Marshall, Escorial y mucho más.' },
    ],
    faqs: [
      {
        question: '¿Cuánto demora tener el préstamo para poder comprar mis electrodomésticos?',
        answer: 'Solicitá tu crédito y vas a tener el dinero disponible en unas pocas horas.',
      },
      {
        question: '¿Cuánto tiempo demora tomar un préstamo en Credicuotas para comprar electro?',
        answer: 'El proceso en Credicuotas es rápido y sencillo. Luego de una breve verificación crediticia, vas a tener el dinero disponible rápidamente para que puedas comprar la cocina, el lavarropas o los electrodomésticos que necesites.',
      },
      {
        question: '¿Cómo es el proceso para tomar un crédito para la compra de electrodomésticos?',
        answer: 'Es un proceso simple que comienza en vos seleccionando el monto a solicitar y el plazo del préstamo. Luego de eso, solo con una foto de frente y dorso de tu DNI original y una selfie es suficiente. Es una breve verificación crediticia y ya luego de aprobado, tendrás tu dinero.',
      },
      {
        question: '¿Los créditos para la compra de electro de Credicuotas se pueden usar en cualquier lugar?',
        answer: 'Esos lo elegís vos y depende del comercio. Podes tomar el préstamo personal para que puedas hacer la compra donde quieras.',
      },
      {
        question: '¿En qué comercios puedo comprar mi electro?',
        answer: 'Podes comprarlos con financiación de Credicuotas en cualquier comercio adherido a la red de comercios que trabajan con nuestros préstamos a lo largo de todo el país.',
      },
    ],
    footer: '* Las siguientes marcas son financiadas a clientes finales en la red de comercios adheridos a Credicuotas Consumo S.A. Su difusión es únicamente con fines publicitarios, informativos y de buena fé para los consumidores. Credicuotas Consumo S.A. declara no ser propietaria, responsable de las marcas que se exponen y no constituye uso a título de la marca. Las marcas LG, Samsung, Whirlpool, BGH, Philco, Atma, Orbis, Marshall, son propiedad registrada siendo sus propietarios titulares de cada marca.',
  },

  LandingRefurbish: {
    title: 'Préstamos para refaccionar tu casa',
    description: 'Financiá en cuotas fijas la compra de materiales y mobiliario para la refacción de tu vivienda, mediante un préstamo de Credicuotas.',
    ctaBanner: '¡Pedilo ahora!',
    howToTitle: '¿Cómo saco un préstamo para refaccionar mi casa?',
    howToSteps: [
      { description: 'Acercate a un comercio adherido', image: ['/images/step1.png', '/images/step1_mobile.png'] },
      { description: 'Brindale tus datos al vendedor', image: ['/images/step2.png', '/images/step2_mobile.png'] },
      { description: '¡Comenzá a refaccionar!', image: ['/images/refacciones/step3.png', '/images/refacciones/step3_mobile.png'] },
    ],
    requirementsTitle: '¿Qué necesito para sacar el préstamo?',
    requirements: [
      { description: 'Ser mayor de 21 años y tener DNI argentino', image: ['/images/req_1.png', '/images/req_1_mobile.png'] },
      { description: 'Número de celular y mail particular', image: ['/images/req_2.png', '/images/req_2_mobile.png'] },
      { description: 'Debés tener tus ingresos en blanco', image: ['/images/req_3.png', '/images/req_3_mobile.png'] },
    ],
    brandsTitle: '¡Comprá lo que necesites para refaccionar tu casa!',
    brandsDescription: 'Muebles de interior, cerámicos y porcelanatos, herramientas, <span style="color: #f2025d">y mucho más!</span>',
    chooseUsTitle: ['¿Porqué elegirnos para financiar la refacción de partes de tu casa?', '¿Por qué elegirnos?'],
    chooseUsMessages: [
      { title: 'Somos simples', description: 'Brindamos préstamos personales para que puedas comprarte lo que necesites para remodelar tu casa, solo con mínimos requisitos.' },
      { title: 'Financiamos en cuotas fijas', description: 'Otorgamos préstamos en cuotas fijas, significa que siempre pagas la misma cuota a lo largo del préstamo.' },
      { title: 'Comprá los materiales y herramientas que necesites', description: 'Contamos con más de 600 comercios adheridos a lo largo del pais, que trabajan con muchas marcas y tipos de productos, desde muebles de interior, cerámicos y porcelanatos, herramientas y más.' },
    ],
    faqs: [
      {
        question: '¿Cuánto demora tener el préstamo para poder comprar materiales para la refacción de mi casa?',
        answer: 'Solicitá tu crédito y vas a tener el dinero disponible en unas pocas horas. Es un proceso muy rápido y sencillo.',
      },
      {
        question: '¿Cuánto tiempo demora tomar un préstamo en Credicuotas?',
        answer: 'El proceso en Credicuotas es rápido y sencillo. Luego de una breve verificación crediticia, vas a tener el dinero disponible rápidamente para que puedas comprar los materiales, las máquinas, herramientas o el mobiliario que necesites.',
      },
      {
        question: '¿Cómo es el proceso para tomar un crédito para la refacción de vivienda?',
        answer: 'Es un proceso simple que comienza en vos seleccionando el monto a solicitar y el plazo del préstamo. Luego de eso, solo con una foto de frente y dorso de tu DNI original y una selfie es suficiente. Es una breve verificación crediticia y ya luego de aprobado, tendrás tu dinero.',
      },
      {
        question: '¿Los préstamos para la compra de materiales para refaccionar mi casa se pueden usar en cualquier lugar?',
        answer: 'A dónde usar el dinero de tu préstamo lo elegís vos. Podes tomar el préstamo personal para que puedas hacer la compra donde quieras.',
      },
      {
        question: '¿En qué comercios puedo comprar los materiales para la refacción de mi casa?',
        answer: 'Puedes comprarlos en cualquier comercio a lo largo de todo el país. Son préstamos personales que luego podés destinar a las marcas o productos que quieras.',
      },
    ],
  },

  // Footer
  footerWe: 'Nosotros',
  footerPersonalLoans: 'Préstamos personales',
  footerBuyOnline: 'Comprá online',
  footerBuyCommerce: 'Comprá en comercios',
  footerCard: 'Tarjeta Credicuotas',
  footerPayServices: 'Pagos y recargas',
  footerSucursal: 'Sucursal',
  footerHelp: 'Ayuda',
  footerInvestors: 'Información Corporativa',
  footerCommerce: 'Soy comercio',
  footerClient: 'Soy cliente',
  footerCommunity: 'Comunidad',
  footerBlog: 'Blog',
  footerWorkWithUs: 'Trabajá con nosotros',
  footerPrivacy: 'Políticas de privacidad',
  footerConsumerDefense: 'Defensa al consumidor',
  footerContract: 'Contratos de adhesión - Ley 24.240 de Defensa del Consumidor',
  financeUserInformation: 'Información a usuarios financieros',
  footerConditions: 'Términos y condiciones',
  footerRepentanceButton: 'Botón de arrepentimiento',
  footerUnsuscribeButton: 'Botón de baja',
  footerCuitText: 'Credicuotas pertenece a CREDICUOTAS CONSUMO S.A. CUIT 30-71091158-0, C.A.B.A. Argentina Copyright Credicuotas 2022',
  footerFacebook: 'Facebook',
  footerInstagram: 'Instagram',
  footerLinkedin: 'Linkedin',
  footerYoutube: 'Youtbe',
  footerUserInformation: 'Información al usuario financiero',
  footerCnv: 'CNV',
  footerRenaper: 'Renaper',
  footerSid: 'SID',
  footerPdp: 'PDP',
  footerBcra: 'BCRA',
  footerDataFiscal: 'Data Fiscal',

  // Pages:

  // Home:
  homeBnpl: 'Comprá online en cuotas sin tarjeta',
  homeWt: 'PRÉSTAMOS PERSONALES',
  homePhysicalCommerce: 'Comprá en cuotas en comercios',
  homePayServicesTitle: 'Pagá tus servicios y recargá tu celular',
  homePayServicesSubtitle: 'Con nosotros podes pagar más de 4.000 servicios de una manera muy simple.',
  homeCreditNow: '¡Préstamos al instante!',
  homeCreditNowSubtitle: 'Pedí efectivo 100% online, recibilo en minutos y pagalo en cuotas fijas.',
  prepaidCardTitle: 'Tarjeta prepaga Credicuotas',
  prepaidCardSubtitle: ' Llevate tu tarjeta prepaga de Credicuotas de manera gratis y al instante',
  prepaidCardButtonCredit: '¡PEDILO AHORA!',
  homeWithUs: '¿Por qué elegirnos?',
  homeWhatNeed: 'Qué necesito',
  prepaidCardStoleOrBrokenTitle: '¿Perdiste o te robaron tu tarjeta?',
  prepaidCardStoleOrBrokenStepOne: 'Ingresá a Autogestión',
  prepaidCardStoleOrBrokenStepTwo: 'Pausala momentáneamente y despausala si la encontras',
  prepaidCardStoleOrBrokenStepThree: 'Bloqueala y pedí la reimpresión de tu tarjeta',
  homeDni: 'Dni',
  homeSelfie: 'Selfie',
  homeValidation: 'Validación',
  prepaidCardForWhatBenefits: '¿Cuáles son sus ventajas?',
  prepaidCardBenefitCashTitle: 'Obtené más crédito',
  prepaidCardBenefitCashSubTitle: 'Te damos más límite de crédito para usar en la tarjeta',
  prepaidCardBenefitPrepaidCardTitle: 'Tu tarjeta prepaga al instante',
  prepaidCardBenefitPrepaidCardSubTitle: 'Tendrás tu tarjeta virtual al instante para hacer tus compras',
  prepaidCardBenefitFreeShippingTitle: 'Llevátela gratis',
  prepaidCardBenefitFreeShippingSubTitle: 'Llega sin cargo a tu domicilio, sin costo de mantenimiento.',
  prepaidCardBenefitCommerceTitle: 'Comprá en comercios',
  prepaidCardBenefitCommerceSubTitle: 'Pagá tu préstamo hasta en 12 cuotas fijas para compras en locales físicos y virtuales.',
  prepaidCardHowGetCardTitle: '¿Cómo pido mi tarjeta?',
  prepaidCardHowGetCardFirstStep: 'Elegí el plan de cuotas de tu préstamo',
  prepaidCardHowGetCardSecondStep: 'Validá tu identidad',
  prepaidCardHowGetCardThirdStep: 'Elegí depositar tu préstamo en la Tarjeta Credicuotas',
  prepaidCardHowGetCardFourthStep: 'Empezá a usarla de manera virtual hasta que la recibas en tu casa.',
  prepaidCardHowUseCardTitle: '¿Cómo la uso?',
  prepaidCardHowUseCardItemOne: 'Usá tu <b>tarjeta virtual</b> desde autogestión.',
  prepaidCardHowUseCardItemTwo: 'Cuando recibas la <b>tarjeta física</b>, usala en comercios de todo el país que acepten <b>Mastercard</b>.',
  homeForWhat: '¿Para qué lo podés usar?',
  homeDesire: 'Obtengo lo que deseo',
  homeSloganWt: 'Préstamos online en el acto: recibí efectivo en tu cuenta en minutos. Simples, rápidos y seguros.',
  homeCTAWt: 'Pedilo YA',
  homeSloganBnpl: '¡Entrá a las tiendas online adheridas y comprá productos en cuotas sin tarjeta de crédito!',
  homeMoreInformation: 'Más información',
  homeSloganPhysicalCommerce: 'Comprá en comercios físicos adheridos y pagá en cuotas fijas. Te financiamos tu compra con un préstamo en el acto.',
  homeWeAreSimple: 'SOMOS SIMPLES',
  homeSloganSimple: 'Brindamos préstamos personales 100% online con mínimos requisitos en solo minutos.',
  homeWeFinanceYou: 'FINANCIAMOS EN CUOTAS FIJAS',
  homeSloganWeFinanceYou: 'Otorgamos préstamos hasta 36 cuotas fijas para que compres lo que quieras.',
  homeThinkOnYou: 'PENSAMOS EN VOS',
  homeSloganThinkOnYou: 'Más préstamos pedís, más beneficios te damos con mayores montos y tasas más bajas.',
  homeRequirementsCredit: '¿Qué necesito para recibir un préstamo?',
  homeRequirementAdult: 'Ser mayor de 21 años y tener DNI Argentino',
  homeRequirementTelephone: 'Tener un número de celular y un mail particular',
  homeRequirementWhite: 'Debés tener ingresos en blanco',
  homeHowToDo: '¿Cómo saco un préstamo?',
  homeEnterYourData: 'Ingresá tus datos',
  homeValidateIdentity: 'Validá tu identidad',
  homeReceive: 'Recibí lo que deseas',
  homeBuyMoto: 'Comprá tu moto',
  homeCellphone: 'Recargá tu celular',
  homeBuyElectro: 'Comprá tus electros',
  homePayService: 'Pagá tus servicios',
  homeFirstQuestion: '¿Qué servicio online ofrece Credicuotas?',
  homeFirstAnswerOne: 'Somos una compañía especialista en finanzas 100% online que ofrece préstamos personales. ',
  homeAnswerCTAOrigination: 'Ingresá a nuestra web ',
  homeFirstAnswerTwo: 'y te guiamos paso a paso cómo sacar el tuyo.',
  homeSecondQuestion: '¿Cómo solicitar un préstamo personal en Credicuotas?',
  homeSecondAnswerOne: 'Solamente necesitás una foto de frente y dorso de tu DNI original y una selfie. ',
  homeSecondAnswerTwo: 'y hacelo vos mismo.',
  homeThirdQuestion: '¿Cuánto dinero online puedo solicitar en Credicuotas?',
  homeThirdAnswer: 'El monto de tu préstamo será determinado mediante el resultado de tu análisis crediticio. Si sos cliente Credicuotas y estás al día con tus pagos, tu disponible para préstamos aumenta. ¿Querés solicitar uno? ',
  homeAnswerCTAOriginationHere: 'Pedilo aquí',
  homeFourQuestion: '¿Cómo recibo el dinero de mis préstamos online con Credicuotas?',
  homeFourAnswer: 'Una vez que el crédito es aprobado, vas a disponer de los fondos en tu cuenta bancaria o digital o en los canales que tenemos habilitados para retirar efectivo.',
  homeMoreQuestion: '¿Tenés más dudas? ',
  homeLinkHelp: 'Ingresa acá',

  // Us
  usTitle: 'Nosotros',
  usSubtitle: 'Te prestamos sin vueltas y sin letra chica.',
  usWhat: '¿Qué hacemos?',
  usCompany: 'Somos una compañía especialista en finanzas',
  usWhatWeDo: 'Ofrecemos préstamos personales online',
  usSimple: 'Simples y rápidos',
  usAccreditation: 'Acreditación inmediata',
  usShop: 'Para compras en comercios',
  usGive: 'En Credicuotas otorgamos:',
  usFirstBenefit: 'Préstamos online con acreditación inmediata',
  usSecondBenefit: 'Préstamos para realizar compras en tiendas virtuales al momento de realizar la compra para financiar sin tarjeta de crédito.',
  usThirdBenefit: 'Préstamos en comercios físicos para financiar tu compra al instante',
  usValueTitle: 'Propuesta de valor',
  usValueTextFirstOne: 'Somos la primera ',
  usValueTextFirstTwo: 'fintech ',
  usValueTextFirstThree: 'de Argentina y  sabemos que el acceso al crédito es clave para concretar proyectos y democratizar efectivamente la economía.',
  usValueTextSecondOne: 'Por eso, brindamos un servicio ',
  usValueTextSecondTwo: 'ágil ',
  usValueTextSecondThree: 'y ',
  usValueTextSecondFour: 'simple',
  usValueTextSecondFive: ', a través de la más ',
  usValueTextSecondSix: 'alta tecnología',
  usValueTextSecondSeven: ', que permite gestionar un préstamo de manera instantánea, para que cada usuario tenga la oportunidad de concretar sus sueños.',
  usHistory: 'Nuestra historia',
  usHistoryFirstYear: '2010-2011',
  usHistoryFirstText: 'Proceso de incubación',
  usHistorySecondYear: '2012',
  usHistorySecondTextOne: 'Nace Credicuotas',
  usHistorySecondTextTwo: 'Buenos Aires - Tucumán',
  usHistoryThirdYear: '2013',
  usHistoryThirdText: 'Grupo Bind adquiere Credishopp S.A.',
  usHistoryFourthYear: '2014',
  usHistoryFourthTextOne: 'Primera aplicación web para ',
  usHistoryFourthTextTwo: 'comercios y préstamos web',
  usHistoryFifthYear: '2016',
  usHistoryFifthTextOne: 'Poincenot Studio',
  usHistoryFifthTextTwo: 'Partnership con Poincenot Studio para la transformación y aceleración digital',
  usHistorySixthYear: '2017',
  usHistorySixthTextOne: '¡Credicuotas crece! ',
  usHistorySixthTextTwo: 'Ingresan nuevos colaboradores',
  usHistorySixthTextThree: 'Nueva aplicación para Comercios.',
  usHistorySeventhYear: '2018',
  usHistorySeventhTextOne: 'Nueva aplicación ',
  usHistorySeventhTextTwo: 'web para préstamos personales.',
  usHistoryEighthYear: '2019',
  usHistoryEighthTextOne: ' Renovamos el producto ',
  usHistoryEighthTextTwo: ' web comercios',
  usHistoryNinethYear: '2020',
  usHistoryNinethText: 'Partnership con Honda y Uber',
  usHistoryTenthYear: '2021',
  usHistoryTenthTextOne: 'Partnership con MercadoLibre para financiar motos',
  usHistoryEleventhYear: '2022',
  usHistoryEleventhTextOne: 'Emisiones frecuentes de obligaciones negociables.',
  usHistoryTwelveYear: '2023',
  usHistoryTwelveTextOne: 'Obtuvimos certificación PCI Compliance',
  usWho: '¿Quienes somos?',
  usWhoText: 'Contamos con un equipo multidisciplinario liderado por profesionales de extensa trayectoria en el mercado financiero y amplios conocimientos en la transformación digital de la industria.',
  usEzequiel: 'Ezequiel Weisstaub',
  usDiego: 'Diego Manobla',
  usCeo: 'CEO',
  usCro: 'CRO',
  usGroup: 'Nuestro grupo',
  usPoincenot: 'Poincenot',
  usBind: 'Bind',
  usLogoLinkedIn: 'LinkedIn',

  // PhysicalCommerce
  physicalLogo: 'Logo de marca',
  physicalHowWorks: 'Como funciona',
  physicalFind: 'Descubri tu comercio más cercano',

  // BuyNowPayLater
  bnplTitle: 'Comprá ahora, pagá después',
  bnplSubtitle: 'Elegí Credicuotas como medio de pago en las tiendas digitales adheridas para pagar en cuotas ¡sin tarjeta!',
  bnplHow: '¿Cómo hacer compras online con Credicuotas?',
  bnplFirstRequirement: 'Llená tu carrito en nuestras tiendas virtuales adheridas',
  bnplSecondRequirement: 'Elegí Credicuotas como medio de pago',
  bnplThirdRequirement: 'Completá tus datos personales',
  bnplFirstQuestion: '¿Cómo funciona el método compre ahora y pague después?',
  bnplFirstAnswerOne: '. Comprá en nuestras tiendas online adheridas y seleccioná Credicuotas como medio de pago.',
  bnplFirstAnswerTwo: '. Elegí el plan de pagos que sea más conveniente para vos.',
  bnplFirstAnswerThree: '. Las cuotas se pagan mensualmente.',
  bnplSecondQuestion: '¿Puedo comprar ahora y pagar después con Credicuotas?',
  bnplSecondAnswer: 'Sí, en nuestra red de tiendas online adheridas podés pagar con Credicuotas de forma rápida y simple.',
  bnplThirdQuestion: '¿Puedo pagar en cuotas sin tarjeta de crédito?',
  bnplThirdAnswer: 'Sí, con Credicuotas podés pagar tus compras en cuotas con tu tarjeta de débito. La primera cuota la pagás el mes siguiente.',
  bnplFourthQuestion: '¿Qué productos puedo comprar con el sistema BNPL?',
  bnplFourthAnswer: 'Podés comprar todos los productos disponibles en nuestras tiendas adheridas.',

  // Commerce
  commerceBenefits: 'Beneficios',
  commerceCharts: 'Charts',
  commercePartners: 'Partners',
  commerceHowWorks: 'Como funciona',

  // Wt
  wtSubtitle: '¡Préstamos al instante! Pedí efectivo 100% online, recibilo en minutos y pagalo en cuotas fijas.',
  wtCalculator: 'Calculadora',
  wtHowWorks: '¿Como solicitar un préstamos online?',
  wtRequiriments: 'Los requisitos son muy simples!',
  wtFaq: 'Preguntas o datos básicos',
  wtCalculate: 'Calculá tu cuota',
  wtHowMany: '¿Cuánto querés pedir?',
  wtPriceFrom: 'Valor cuota desde:',
  wtQuantity: 'Cantidad de cuotas',
  wtFirstExpiration: 'Primer Vencimiento',
  wtButtonCredit: 'Pedilo ahora',
  wtLegal: '* Sujeto a verificación crediticia',
  wtFirstQuestion: '¿Puedo pedir préstamos online en Credicuotas?',
  wtFirstAnswerOne: 'Sí, de forma simple y rápida podés pedir tu ',
  wtFirstAnswerCTA: 'préstamo aquí',
  wtFirstAnswerTwo: ' y pagalo en cuotas fijas.',
  wtSecondQuestion: '¿Cuáles son los requisitos para solicitar un préstamo online?',
  wtSecondAnswerOne: 'Sólo es necesario estar radicado en Argentina con DNI argentino y ser mayor de 21 años, disponer de ingresos demostrables y cuenta bancaria. ',
  wtSecondAnswerTwo: ' y el sistema te indicará el monto que podés solicitar.',
  wtThirdQuestion: '¿Cómo se pagan las cuotas de los préstamos 100% online?',
  wtThirdAnswer: 'Las cuotas pueden ser abonadas por distintos medios de pago: transferencia bancaria o depósito bancario, tarjeta de débito, débito directo en cuenta o en medios de cobro como: Rapipago, Pago Fácil, Cobro Express y Mercado Pago. ',
  wtFourthQuestion: '¿Cuánto tarda Credicuotas en otorgar préstamos personales con DNI?',
  wtFourthAnswer: 'Una vez que el crédito es aprobado, vas a disponer de los fondos en tu cuenta bancaria en minutos.',

  // Wc
  wcTitle: 'Comprá en comercios',
  wcSubtitle: 'Comprá lo que quieras y pagá en cuotas sin tarjeta de crédito en más de 400 comercios adheridos.',
  wcHow: '¿Cómo saco un préstamo en comercios?',
  wcComeCommerce: 'Acercate a un comercio adherido',
  wcVendorData: 'Brindale tus datos al vendedor',
  wcProduct: 'Llevate tu producto',
  wcRequirements: '¿Qué necesito para recibir un préstamo?',
  wcCommerce: 'Comprá en nuestros comercios físicos adheridos',
  wcOwnerCommerce: '¿Tenés un comercio y querés formar parte de Credicuotas?',
  wcWriteUs: 'Escribinos a:',
  wcCommerceMail: 'comercios@credicuotas.com.ar',
  wcAreas: '¿Qué rubros trabajamos?',
  wcMoto: 'Motos y bicicletas',
  wcElectro: 'Electrodomésticos y tecnología',
  wcFurniture: 'Muebles y colchones',
  wcInstruments: 'Instrumentos musicales',
  wcFirstQuestion: '¿Cómo hago para adherirme como comercio?',
  wcFirstAnswer: 'Si tu negocio está en el rubro de electrodomésticos, electrónica en general, motocicletas, bicicletas, instrumentos musicales, hogar, colchones y muebles, podes comunicarte con nosotros al mail comercios@credicuotas.com.ar y dejanos los datos de tu comercio para que te contactemos.',
  wcSecondQuestion: '¿Cómo funcionan los préstamos en comercios?',
  wcSecondAnswer: 'El cliente compra el producto que desea en el comercio adherido a Credicuotas, y paga con un préstamo personal en cuotas fijas. El comercio recibe el monto total de la compra sin ningún tipo de riesgo.',
  wcThirdQuestion: '¿Qué rubros de comercio no están habilitados para adherirse?',
  wcThirdAnswer: 'Por el momento no estamos trabajando con comercios de indumentaria, calzados, casas de deporte, supermercados, decoración, librerías, pinturerías y materiales de construcción.',
  wcFourthQuestion: '¿Por qué me conviene ofrecer los préstamos de Credicuotas como medio de pago?',
  wcFourthAnswer: 'Está probado que al ofrecer mayores alternativas de pago al cliente, el volumen de ventas para el comercio se incrementa. Credicuotas es una excelente opción para los clientes que quieren pagar en cuotas fijas sus consumos.',
  wcFindCommerce: 'Descubrí el comercio más cercano',
  wcProvince: 'Provincia',
  wcSearch: 'Buscar por calle, localidad o nombre comercial',

  // Help
  helpSubtitle: 'Acá vas a encontrar las respuestas a tus dudas sobre nuestros productos.',
  helpProducts: '¿Sobre qué productos tenés dudas?',
  helpAboutCredicuotas: 'Sobre Credicuotas',
  helpWc: 'Préstamos en Comercios',
  helpWt: 'Préstamos personales',
  helpBnpl: 'Tienda virtual',
  helpCommerce: 'Soy Comercio',
  helpPrepaidCard: 'Tarjeta Credicuotas',
  helpWhoWeAre: '¿Quiénes somos?',
  helpAnswerWhoWeAre: 'Somos una compañía especialista en finanzas 100% online que ofrece préstamos personales online a individuos. Contamos con un equipo multidisciplinario, liderado por profesionales de extensa trayectoria en el mercado financiero y amplios conocimientos en colocación de préstamos y transformación digital de la industria. Lideramos el segmento de préstamos personales, con más de 8 años en el mercado financiero. CREDICUOTAS CONSUMO SA con CUIT 30-71091158-0 con domicilio en Arenales 707, piso 7 (CABA) es la razón social de la marca Credicuotas.',
  helpWhoWeAreSecondQuestion: '¿Qué ofrecemos?',
  helpAnswerWhoWeAreSecondAnswerOne: 'Otorgamos préstamos personales sin garantía a individuos. Nuestra modalidad de ofrecimiento es 100% online y sin papeles. Entregamos préstamos al instante por medio de ',
  helpAnswerWhoWeAreSecondAnswerTwo: ', por nuestra sucursal física en la provincia de Tucumán y por la amplia red de comercios adheridos a los largo de todo el país.',
  helpWhoWeAreThirdQuestion: '¿Tenemos sucursal física?',
  helpAnswerWhoWeAreThirdAnswer: 'Sí, nuestra sucursal está en San Martín 797, San Miguel de Tucumán, en la provincia de Tucumán. Los horarios de atención al público son de Lunes a Viernes de 8.30 a 13.00 hs y de 17.00 a 20.30 hs.',
  helpChannels: 'Canales de atención',
  helpBranch: 'Nuestra sucursal',
  helpBranchInfoOne: 'Si vivís en San Miguel de Tucumán, podes acercarte a nuestras oficinas en ',
  helpBranchInfoTwo: 'Gral. José de San Martín 797, San Miguel de Tucumán. ',
  helpBranchInfoThree: 'Horarios de atención al público de ',
  helpBranchInfoFour: 'lunes a viernes de 9 a 13hs y de 17 a 21hs',
  helpOurWeb: 'nuestra web',
  // helpWt faq
  helpWtFirstQuestion: '¿Quienes pueden pedir préstamos online en Credicuotas?',
  helpWtFirstAnswerOne: 'TODOS. Si necesitas contar con efectivo al instante y queres que la experiencia sea simple, rápida y segura, entonces ingresá a ',
  helpWtFirstAnswerTwo: ' y pedilo. Lo devolves en cuotas fijas mensuales.',
  helpWtSecondQuestion: '¿Cuáles son los requisitos para solicitar un préstamo online?',
  helpWtSecondAnswer: 'Sólo es necesario estar radicado en Argentina con DNI argentino y ser mayor de 21 años, disponer de ingresos demostrables y cuenta bancaria. Te vamos a pedir que tengas un teléfono celular activo y un mail particular. En segundos podés consultar el monto al cual podes acceder. Ingresá a ',
  helpWtThirdQuestion: '¿Qué documentación necesito para solicitar el préstamo personal?',
  helpWtThirdAnswer: 'Te pediremos una foto frente y dorso de tu DNI original, una selfie para validar tu identidad y que tengas una cuenta bancaria a tu nombre.',
  helpWtFourthQuestion: '¿Cuánto dinero puedo pedir por la web de Credicuotas?',
  helpWtFourthAnswerOne: 'El monto de tu préstamo personal será calculado mediante el resultado de tu análisis crediticio. El monto mínimo del préstamo es de $10.000 y podemos prestarte hasta $300.000. Tené en cuenta que si ya sos cliente Credicuotas y estás al día con tus pagos, tu disponible para préstamos aumenta. Si aún no sos cliente ingresá a ',
  helpWtFourthAnswerTwo: ' y pedilo.',
  helpWtFourthAnswerThree: 'y el sistema te indicará el monto que podés solicitar.',
  helpWtFifthQuestion: '¿Cómo hago para saber si puedo pedir un préstamo online?',
  helpWtFifthAnswerOne: 'Usando el simulador de préstamos podrás averiguarlo! Esta herramienta te va a permitir ver un préstamo online desde la primera hasta la última cuota. Ingresá en ',
  helpWtFifthAnswerTwo: ' y calcúlalo.',
  helpWtSixthQuestion: '¿Cuáles son los pasos a seguir para sacar un préstamo personal?',
  helpWtSixthAnswerOne: 'Podes ingresar a ',
  helpWtSixthAnswerTwo: ' y calcular tu préstamo. Una vez que estés decidido, te pediremos que nos brindes un número de celular y un mail para calificarte. Luego seleccionas el monto y cantidad de cuotas fijas mensuales, que es el plazo para que puedas devolver el préstamo.',
  helpWtSixthAnswerThree: 'Te pediremos que cargues una foto del frente y dorso de tu DNI y que te tomes una selfie.',
  helpWtSixthAnswerFour: 'Para poder devolver el préstamos necesitamos que nos brindes una tarjeta de débito y para desembolsar el préstamos nos tenes que dejar el CBU de la cuenta bancaria.',
  helpWtSixthAnswerFive: 'Por último, firmas el contrato de forma digital y esperas unos minutos hasta recibir nuestra llamada de bienvenida, en ese momento tenes disponible tu dinero.',
  helpWtSeventhQuestion: '¿Cómo recibo el dinero de mi préstamo online?',
  helpWtSeventhAnswer: 'Una vez que el crédito es aprobado, vas a recibir una llamada de bienvenida y vamos a validar tu identidad y en forma inmediata vas a disponer de los fondos en la cuenta bancaria que nos informaste.',
  helpWtEighthQuestion: '¿Cómo pago la cuota de mi préstamo personal?',
  helpWtEighthAnswerOne: 'La cuota se debitará mensualmente entre el 1er y 6to día del mes, de la tarjeta de débito que nos informaste cuando te entregamos el préstamo online.',
  helpWtEighthAnswerTwo: 'También tenés la opción de abonar tu cuota mediante transferencia a CVU del préstamo o mercadopago o QR a través de billeteras virtuales.',
  helpWtEighthAnswerThree: ' Si vas a transferir asegurate que el CVU sea el de tu préstamo, Credicuotas no tiene alias.',
  helpWtEighthAnswerFour: 'Otra forma de pagar tus cuotas en puntos de cobro físicos: Rapipago, Pago Fácil y Cobro Express lo podes hacer con el código de barra del cupón de pago que obtenés desde nuestro canal de autogestión: ',
  helpWtEighthAnswerFive: 'Ingresá acá.',
  helpWtNinethQuestion: '¿Cuándo vencen las cuotas del préstamo? ',
  helpWtNinethAnswerOne: 'El vencimiento es el día 1 (uno) de cada mes. Y te damos el beneficio de pagar tu cuotas hasta el día 6 sin interés.',
  helpWtNinethAnswerTwo: 'Posterior a esa fecha se cobrarán intereses por retrasos.',
  helpWtNinethAnswerThree: 'Si solicitaste que la modalidad de pago del préstamo sea con tarjeta de débito, se cobrará la cuota mensual desde el día 1 al día 6 de cada mes.',
  helpWtNinethAnswerFourth: 'Tenemos un beneficio para todos los clientes: Si solicitaste un préstamo a partir del día 21 del mes, la fecha de vencimiento cambia al mes subsiguiente',
  helpWtTenthQuestion: '¿Puedo renovar mi préstamo online?',
  helpWtTenthAnswerOne: 'Si, siempre podrás renovar tu préstamo. Solo considera que es importante que tengas las cuotas de tus préstamos anteriores al día. Ingresá a ',
  helpWtTenthAnswerTwo: ' y averiguá si podés renovar.',
  helpWtEleventhQuestion: '¿Cuál es el contrato que firmo con Credicuotas?',
  helpWtEleventhAnswerOne: 'Te compartimos el modelo de contrato que firmás con nosotros.',
  helpWtEleventhAnswerTwo: '“Contratos de adhesión – Ley 24.240 de Defensa del Consumidor”.',
  // helpWc faq
  helpWcFirstQuestion: '¿Cómo puedo solicitar un préstamos personal para comprar en un comercio?',
  helpWcFirstAnswer: 'Si estás en un comercio adherido a Credicuotas, podes pedirle al vendedor que calcule la oferta que tenemos para vos y  te guiará en los pasos a seguir.',
  helpWcSecondQuestion: '¿Cuales son los productos puedo comprar con el préstamo de Credicuotas?',
  helpWcSecondAnswer: 'Podes comprar cualquier producto que se vende en el comercio que esta adherido a Credicuotas.',
  helpWcThirdQuestion: '¿Puedo financiar el total de la compra con un préstamo Credicuotas?',
  helpWcThirdAnswer: 'Sí, nosotros te calificamos mediante un análisis de tu perfil crediticio y te ofrecemos un monto máximo para el préstamo personal.  Vos decidís qué monto queres llevarte y le informas al vendedor  la cantidad de cuotas fijas en que queres devolverlo.',
  helpWcFourthQuestion: '¿Cuál es el tiempo de aprobación del préstamo en un comercio?',
  helpWcFourthAnswer: 'En el comercio te brindamos una aprobación inmediata y en solo minutos recibirás un llamado del equipo de Credicuotas para darte la bienvenida y así  finalizar el proceso del préstamo.',
  helpWcFifthQuestion: '¿Quién realiza la solicitud del préstamo personal en un comercio?',
  helpWcFifthAnswer: 'El vendedor del comercio será la persona encargada de completar tus datos para solicitar el préstamo personal a Credicuotas.',
  helpWcSixthQuestion: '¿Dónde pago el préstamo de Credicuotas que tomé en el comercio?',
  helpWcSixthAnswerOne: 'En el momento de la compra, el vendedor te entregará una cuponera de pago que te servirá para todos nuestros canales de cobro. También podes descargarla desde el canal de autogestión, seleccionando la opción “Descargar cuponera de pago”.',
  helpWcSixthAnswerTwo: 'Los canales de pago habilitados son:',
  helpWcSixthAnswerThree: 'Canal digital, ingresando a autogestión en ',
  helpWcSixthAnswerFour: 'mi.credicuotas.com.ar',
  helpWcSixthAnswerFive: ' podrás pagar por transferencia (CVU), desde tu billetera virtual, ó por mercado pago.',
  helpWcSixthAnswerSix: 'Canales físicos, como Rapipago, Pago Fácil, Cobro Express y Ripsa.',
  helpWcSeventhQuestion: '¿Cuántos comercios hay adheridos a Credicuotas?',
  helpWcSeventhAnswer: 'Hoy contamos con más de 600 comercios adheridos a los largo de todo el país. Consulta a tu comercio más cercano si ya trabaja con los préstamos de Credicuotas.',
  helpWcEighthQuestion: '¿Puedo renovar mis préstamos 100% online en Credicuotas?',
  helpWcEighthAnswerOne: 'Sí, es posible. Para renovar tu préstamo es importante que tengas las cuotas de tus préstamos al día. Averiguá si podés ',
  helpWcEighthAnswerTwo: 'renovar aquí.',
  // helpBnpl faq
  helpBnplFirstQuestion: '¿Cómo funciona el método compre ahora y pague después?',
  helpBnplFirstAnswerOne: 'Comprá en nuestras tiendas online adheridas y seleccioná Credicuotas como medio de pago.',
  helpBnplFirstAnswerTwo: 'Elegí el plan de pagos que sea más conveniente para vos.',
  helpBnplFirstAnswerThree: 'Para finalizar, mensualmente pagarás tus cuotas.',
  helpBnplSecondQuestion: '¿Puedo comprar ahora y pagar después con Credicuotas?',
  helpBnplSecondAnswer: 'Sí, en nuestra red de tiendas online adheridas podés pagar con Credicuotas de forma rápida y simple. Ingresá aquí para conocerlas.',
  helpBnplThirdQuestion: '¿Puedo pagar en cuotas sin tarjeta de crédito?',
  helpBnplThirdAnswer: 'Sí, con Credicuotas podes pagar tus compras en cuotas con tu tarjeta de débito. La primera cuota la pagas el mes siguiente.',
  helpBnplFourthQuestion: '¿Qué productos puedo comprar con el sistema BNPL?',
  helpBnplFourthAnswer: 'Podés comprar todos los productos disponibles en nuestras tiendas adheridas.',
  helpBnplFifthQuestion: '¿Cuántas compras puedo hacer pagando con BNPL?',
  helpBnplFifthAnswer: 'Podés realizar más de una compra, dependerá de tu límite de crédito disponible.  Si sos cliente de Credicuotas, podrás mejorar tu límite si tus pagos están al día.',
  helpBnplSixthQuestion: '¿Cómo pago las cuotas de mi compra BNPL?',
  helpBnplSixthAnswer: 'Los pagos de las cuotas se realizarán automáticamente con la tarjeta de débito que asociaste.',
  helpBnplSeventhQuestion: '¿Cuáles son los beneficios de comprar ahora y pagar después?',
  helpBnplSeventhAnswer: 'Pagá rápidamente tu compra en cuotas con Credicuotas sin usar tarjeta de crédito.',
  // helpCommerce faq
  helpCommerceFirstQuestion: '¿Cómo hago para adherirme como comercio?',
  helpCommerceFirstAnswerOne: 'Si tu negocio está en el rubro de electrodomésticos, electrónica en general, motocicletas, bicicletas, instrumentos musicales, hogar, colchones y muebles, podes comunicarte con nosotros al mail ',
  helpCommerceFirstAnswerTwo: 'comercios@credicuotas.com.ar',
  helpCommerceFirstAnswerThree: ' y dejanos los datos de tu comercio para que te contactemos.',
  helpCommerceSecondQuestion: '¿Cómo funcionan los préstamos en comercios?',
  helpCommerceSecondAnswer: 'El cliente compra el producto que desea en el comercio adherido a Credicuotas, y paga con un préstamo personal en cuotas fijas. El comercio recibe el monto total de la compra sin ningún tipo de riesgo.',
  helpCommerceThirdQuestion: '¿Qué rubros de comercio no están habilitados para adherirse?',
  helpCommerceThirdAnswer: 'Por el momento no estamos trabajando con comercios de indumentaria, calzados, casas de deporte, supermercados, decoración, librerías, pinturerías y materiales de construcción.',
  helpCommerceFourthQuestion: '¿Por qué me conviene ofrecer los préstamos de Credicuotas como medio de pago?',
  helpCommerceFourthAnswer: 'Está probado que al ofrecer mayores alternativas de pago al cliente, el volumen de ventas para el comercio se incrementa. Credicuotas es una excelente opción para los clientes que quieren pagar en cuotas fijas sus consumos.',
  helpCommerceFifthQuestion: '¿Cómo se ofrecen los préstamos de Credicuotas en el comercio?',
  helpCommerceFifthAnswer: 'Es simple, al momento del pago, consultale a tu cliente si desea pagar con un préstamo de Credicuotas. Si es así, ingresas a nuestra plataforma y seguís los pasos. En solo 4 pasos el préstamos es otorgado y podes entregar el producto al cliente.',
  helpPrepaidCardFirstQuestion: '¿Cómo obtengo la tarjeta Credicuotas Mastercard?',
  helpPrepaidCardFirstAnswer: 'Cuando tomés tu préstamo en Credicuotas elegí la tarjeta como forma de desembolso. Te vas a llevar tu plata en tu tarjeta.',
  helpPrepaidCardSecondQuestion: '¿Dónde puedo usar la tarjeta Credicuotas Mastercard?',
  helpPrepaidCardSecondAnswer: 'En cualquier comercio físico o virtual que acepte Mastercard. También podes extraer efectivo en las redes Link y Banelco.',
  helpPrepaidCardThird3Question: '¿Tiene costos?',
  helpPrepaidCardThird3Answer: 'La tarjeta Credicuotas no tiene costos de emisión ni mantenimiento.',
  helpPrepaidCardFourthQuestion: '¿Cuándo recibo la tarjeta Credicuotas Mastercard?',
  helpPrepaidCardFourthAnswerShort: 'La tarjeta física la recibirás como máximo en 10 días hábiles.',
  helpPrepaidCardFourthAnswerLong: 'La tarjeta física la recibirás como máximo en 10 días hábiles. Hasta que la recibas en tu domicilio contás con tu tarjeta virtual con la que podes hacer una compra  o pago online. Ingresá en Autogestión para ver los datos de la tarjeta.',
  helpPrepaidCardFifthQuestion: '¿Dónde veo mi tarjeta Credicuotas virtual?',
  helpPrepaidCardFifthAnswer: 'Hasta que la recibas en tu domicilio contás con tu tarjeta virtual con la que podes hacer una compra  o pago online. Ingresá en Autogestión para ver los datos de la tarjeta.',
  helpPrepaidCardSixthQuestion: '¿Cómo activo la tarjeta física?',
  helpPrepaidCardSixthAnswer: 'Ingresá <a href="${0}" target="_blank" rel="noopener noreferrer nofollow" >autogestión</a> y en la sección Mi tarjeta activá tu tarjeta física ingresando los 16 dígitos que están al dorso la misma y creando un PIN de seguridad que quieras. Por tu seguridad, esa información no lo compartas con nadie.',
  helpPrepaidCardSeventhQuestion: '¿Cómo recargo saldo a mi tarjeta Credicuotas?',
  helpPrepaidCardSeventhAnswer: 'Para recargar tu tarjeta tenes que tomar un préstamo con tasas especiales.',
  helpPrepaidCardEigthQuestion: '¿Dónde veo el saldo y los movimientos de mi tarjeta?',
  helpPrepaidCardEigthAnswer: 'Ingresá a la Sección Mi tarjeta en Autogestión para ver todos los consumos de tu tarjeta.',
  helpPrepaidCardNineQuestion: '¿Qué hago si la pierdo o me la roban?',
  helpPrepaidCardNineAnswer: 'En la sección Mi Tarjeta de Autogestión, bloqueala y pedí la reimpresión.',

  // Blog
  blogCredicuotas: 'Blog Credicuotas',
  communityNotes: 'Notas',
  communityDistinguished: 'Últimas notas',
  communityLoadMore: 'Ver notas anteriores',
  communityRelated: 'Notas recomendadas',
  legalDisclaimer: 'Se aconseja al público verificar esta información en forma independiente, a los efectos de encontrarse en condiciones de tomar una decisión basada en la misma. Ni la información, ni las opiniones contenidas en el presente blog informativo constituyen una oferta de servicios de asesoramiento financiero sino que tienen un mero fin informativo y/o de divulgación de conceptos financieros básicos con objetivos netamente educacionales o de formación elemental en la materia. La información proporcionada no tiene por objeto proveer los fundamentos para la realización de ninguna evaluación financiera o de otro tipo. El destinatario de la información proporcionada deberá evaluar por sí mismo la información provista y, eventualmente, consultar con sus propios asesores financieros. Aunque la información vertida en el presente blog informativo se considera precisa, puede contener imprecisiones técnicas o errores tipográficos. Cualquier información o estimación vertida en el mismo puede ser modificada sin previo aviso. La reproducción o distribución total o parcial de este informe y/o artículo por terceros está prohibida, salvo con permiso. Todos los derechos reservados.',

  // Jobs
  jobs: 'Empleos',
  jobsValues: '4 valores',
  jobsWhy: 'Por qué trabajar con Credicuotas?',
  jobsWhat: 'Qué es Credicuotas?',
  jobsContact: 'Contacto RRHH',

  // Investors
  investorsBannerTitle: 'Conocenos',
  investorsBannerSubtitle: 'Somos la primera <b>fintech lending</b> de Argentina',
  investorsAboutCredicuotas: 'Sobre Credicuotas',
  investorsAboutCredicuotasParagraph1: 'Somos una empresa argentina que brinda soluciones financieras accesibles para nuestros clientes, comprometida con la transparencia, la eficiencia y la innovación. Formamos parte del Grupo BIND.',
  investorsAboutCredicuotasParagraph2: 'Con una sólida trayectoria y un equipo altamente calificado, estamos enfocados en crear valor a largo plazo para nuestros clientes y red de comercios adheridos, y contribuyendo al crecimiento sostenible para la economía.',
  investorsAboutCredicuotasParagraph3: 'Nos enorgullece ser un referente en la financiación de bienes de consumo, y trabajamos día a día para que nuestros clientes puedan alcanzar sus metas.',
  investorsDirectorsTitle: 'Mensajes de los directores',
  investorsDirectors1Name: 'Andrés Meta',
  investorsDirectors1Position: 'Director y co-founder',
  investorsDirectors1Phrase: 'Credicuotas se ha posicionado como una <b>empresa líder</b> de consumer finance bajo un modelo de negocio 100% Fintech imprescindible para un contexto volátil como el de nuestro mercado. Desde el Grupo Bind, acompañamos sus desarrollos y desafíos llenándonos de orgullo todos los logros obtenidos desde su creación.',
  investorsDirectors2Name: 'Ezequiel Weisstaub',
  investorsDirectors2Position: 'CEO',
  investorsDirectors2Phrase: 'Estoy convencido que la forma de democratizar la economía es a través de los créditos que impulsan proyectos personales.<br />Agradecemos la confianza y apoyo continuo de nuestros inversores, lo que nos impulsa a seguir creciendo y <b>generando valor para todos nuestros clientes.</b>',
  investorsMoreAboutUs: 'Más sobre nosotros',
  investorsAboutBox1Title: '+500.000',
  investorsAboutBox1Description: 'clientes',
  investorsAboutBox2Title: 'A',
  investorsAboutBox2Description: 'Calificación de riesgo FIX de largo plazo',
  investorsAboutBox3Title: '+15',
  investorsAboutBox3Description: 'Emisiones en el mercado de capitales',
  investorsAboutBox4Title: '+ $17.000 Millones',
  investorsAboutBox4Description: 'Emitidos en el mercado de capitales',
  investorsContactUsText: 'Para contactarnos por consultas para inversiones escribinos a ',
  investorsContactUsEmail: 'inversores@credicuotas.com.ar',
  investorsOurHistoryTitle: 'Nuestra historia',
  investorsFinancialReportTitle: 'Reportes<br/>financieros',
  investorsFinancialReportCTA: 'Acceder',
  investorsPressTitle: 'Credicuotas en la prensa',
  investorsPress: [
    {
      title: 'La fintech Credicuotas logró su segundo fondeo del año por $6.590 millones',
      description: 'Es Serie VIII, por un valor récord de 6.590 millones de pesos a tasa variable en base a BADLAR.',
      footer: '13 de junio de 2024 - Ámbito',
      link: 'https://www.ambito.com/agronegocios/la-fintech-credicuotas-logro-su-segundo-fondeo-del-ano-6590-millones-n6015299',
    },
    {
      title: 'El 15% de todos los créditos del sistema financiero ya son a través de fintech',
      description: 'El primer Informe de Crédito Fintech brindó un panorama del crecimiento auspicioso que viene teniendo el sector.',
      footer: '24 de Mayo 2024 - Perfil',
      link: 'https://www-perfil-com.cdn.ampproject.org/c/s/www.perfil.com/noticias/amp/opinion/mas-de-5-millones-de-personas-tienen-un-credito-fintech-en-la-argentina.phtml',
    },
    {
      title: 'Credicuotas logró una nueva colocación de capital',
      description: 'Emitió $ 2.000 millones en el mercado cerrando su sexta ON en menos de 2 años. La emisión fue organizada por BIND con vencimiento a 12 meses.',
      footer: '05 de octubre de 2023 - Ámbito',
      link: 'https://www.ambito.com/negocios/credicuotas-logro-una-nueva-colocacion-capital-n5839115',
    },
    {
      title: 'La primera fintech que sube su calificación de riesgo en 2023: cuál es y cómo lo logró',
      description: 'En medio de un contexto de incertidumbre macroeconómica, esta fintech logró alcanzar la calificación de largo plazo A.',
      footer: '14 de Julio 2023 - Ámbito',
      link: 'https://www.ambito.com/negocios/la-primera-fintech-que-sube-su-calificacion-riesgo-2023-cual-es-y-como-lo-logro-n5770364',
    },
    {
      title: 'Credicuotas emitió $4.000 millones en el mercado',
      description: 'Fue su quinta ON en menos de 2 años. La emisión fue organizada por BIND y emitida en dos series con vencimiento a 12 y 18 meses respectivamente.',
      footer: '05 de Junio 2023 - RoadShow',
      link: 'https://www.roadshow.com.ar/credicuotas-emitio-4-000-millones-en-el-mercado/',
    },
  ],
  investorsPressCTALabel: 'Leer Más',
  investorsHistory1title: '2010-2011',
  investorsHistory1Description: 'Proceso de incubación',
  investorsHistory2title: '2012',
  investorsHistory2Description: 'Nace Credicuotas<br/>Buenos Aires - Tucuman',
  investorsHistory3title: '2013',
  investorsHistory3Description: 'Grupo BIND adquiere Credishopp S.A.',
  investorsHistory4title: '2014',
  investorsHistory4Description: 'Primera aplicación web para comercio y préstamos web',
  investorsHistory5title: '2016',
  investorsHistory5Description: 'Partnership con Poincenot Studio para la transformación digital',
  investorsHistory6title: '2017',
  investorsHistory6Description: '¡Credicuotas crece! Ingresan nuevos colaboradores',
  investorsHistory7title: '2018',
  investorsHistory7Description: 'Nueva aplicación web para préstamos personales.',
  investorsHistory8title: '2019',
  investorsHistory8Description: 'Renovamos el producto web comercio.',
  investorsHistory9title: '2020',
  investorsHistory9Description: 'Partnership con Honda y Uber',
  investorsHistory10title: '2021',
  investorsHistory10Description: 'Partnership con Mercado Libre para financiar motos',
  investorsHistory11title: '2022',
  investorsHistory11Description: 'Emitimos Obligaciones Negociables por más de $10 MM',
  investorsHistory12title: '2023',
  investorsHistory12Description: 'Obtuvimos certificación PCI Compliance',

  // Finance User Information
  infoContact: '¿Querés comunicarte con nosotros?',
  inforResponsable: 'Responsables de Atención al Usuario Financiero',
  infoFirstContactUs: '¿Necesitas ayuda personalizada?',
  infoFirstPerson: 'Titular: Eliana Costilla',
  infoSecondPerson: 'Suplente: Nadia Aimi',
  infoClientMail: 'Mail: clientes@credicuotas.com.ar',
  infoTelephoneContactNumber: 'Teléfono de contacto: 0800 888 2733',
  infoAddress: 'Domicilio laboral: Arenales 707, piso 7 (C1054AAF) CABA',
  infoFirstP: 'En caso de no estar de acuerdo con nuestra respuesta, podes comunicarte con la Gerencia Principal de Protección a Usuarios Financieros a través de su sitio: www.usuariosfinancieros.gob.ar',
  infoRights: 'Tus derechos como usuario de servicios financieros:',
  infoChannels: 'Canales de contacto:',
  infoWhatsapp: 'Whatsapp Chat',
  infoWhatsappNumber: '+54 9 11 6457 5091',
  infoMail: 'Mail',
  infoMailClient: 'clientes@credicuotas.com.ar',
  infoTelephone: 'Teléfono',
  infoTelephoneNumber: '0800 888 2733',
  infoFees: 'Consultá nuestros productos y tasas',

  // ConsumerProtection
  consumerTitle: 'Defensa al consumidor',
  consumerAskHere: 'Podés realizar tus consultas aquí',
  consumerFirstP: 'Defensa de las y los Consumidores.',
  consumerHere: 'aquí',
  consumerSecondP: 'Dónde y cómo reclamar si sos residente en C.A.B.A',
  consumerThirdP: 'Consultá la Ley de defensa del consumidor.',
  consumerProductsLink: 'Productos y tasas ',
  consumerNotifications: 'Notificaciones legales',
  consumerLegalInfo: 'Podés contactarte con el área de legales de Credicuotas al correo legales@credicuotas.com.ar a los efectos de notificar:',
  consumerAudience: 'Audiencia de Defensa del Consumidor.',
  consumerJudge: 'Mediación Prejudicial.',
  consumerOmic: 'Audiencia OMIC perteneciente a un Municipio.',
  consumerCoprec: 'Audiencia COPREC',
  consumerTribunal: 'Audiencia Tribunales Arbitrales de Consumo',

  // CancelCredit
  cancelTitle: 'Cancelá tu préstamo',
  cancelTitleBody: '¿Cómo cancelo el préstamo que solicite?',
  cancelCalendar: 'Podés hacerlo siempre y cuando no hayan pasado más de 10 días hábiles desde que te entregamos la plata.',
  cancelClient: 'Llená el formulario de cancelación.',
  cancelContact: 'Te estaremos contactando desde nuestros centro de atención al cliente para avanzar con la devolución.',
  cancelData: 'Completá tus datos',

  // Form Cancel Credit
  formCancelNameTitular: 'Nombre del titular del préstamo',
  formCancelName: 'Nombre',
  formCancelDni: 'DNI',
  formCancelEmail: 'Correo electrónico',
  formCancelWhy: '¿Por qué querés cancelar el préstamo?',
  formCancelSend: 'Enviar',
  formCancelSuccess: 'Hemos recibido tu solicitud',
  formCancelTicketNumber: 'Registramos tu solicitud número ',
  formCancelSuccessContact: 'En un plazo de 48 hs estaremos contactándonos para darte respuesta.',
  formCancelThanks: 'Gracias',
  formCancelCredicuotasTeam: 'Equipo de Credicuotas',
  formCancelError: 'Su solicitud no pudo ser enviada correctamente',
  formCancelTryAgain: 'Por favor vuelva a intentarlo',

  // Note Card
  noteCardViewMore: 'Leer más',

  // Blog Note
  blogNoteComeBack: 'Volver',

  // WhatsApp
  wppName: 'Préstamos WhatsApp',
  wppTitle: '¡Pedí tu Préstamo desde WhatsApp!',
  wppButton: 'Comenzar',
  wppClickButton: 'Al hacer click se abrirá automáticamente tu whatsapp, para que nos hables.',
  wppBusiness: 'Whatsapp business, 100% validado',
  wppAsk: 'Consultá tus dudas',
  wppChat: 'Pedilo chateando, lo transferimos en 5 min. a tu cuenta bancaria',
  wppPhoto: 'Solo necesitas sacarte una foto y completar algunos datos',

  // Uber
  uberName: 'Préstamos Uber',
  uberTitle: 'Préstamos exclusivos para socios conductores y socias conductoras del programa Uber Pro',
  uberSubtitle: '100 % online, en el acto y sin vueltas. ¡Te reconocemos tus ganancias en Uber!',
  uberButton: 'Pedilo ahora',
  uberBenefits: 'Con Uber y Credicuotas accedé a distintos beneficios con una mejor oferta con más plata y mejor cuota.',
  uberPartner: 'Si sos socia o socio conductor del programa Uber Pro, Diamante, Platino, Oro y Azul, accederás a distintos beneficios hasta un 25% en el valor de la cuota.',
  uberPaperless: 'Sin filas ni papeleos. Hacé todo desde tu celular y 100% online',
  uberWorks: '¿Cómo funciona?',
  uberStep: 'Seleccioná el monto de tu préstamo, el plazo de pago y obtené tu préstamo en minutos.',
  uberUse: 'Usalo para arreglar tu vehículo, pagar el seguro, llenar el tanque o salir de un apuro.',
  uberRequirements: '¿Lo querés? Lo tenes. Solo necesitas...',
  uberPartners: 'Ser una socia o un socio conductor Diamante, Platino, Oro o Azul del programa Uber Pro',
  uberDocument: 'Tener DNI argentino permanente (no temporal)',
  uberPhone: 'Un celular y un e-mail personal.',

  // Seac
  seacName: 'Carga Virtual',
  seacTitle: 'Préstamos exclusivos para clientes de Carga Virtual',
  seacSubtitle: '100 % online, en el acto y sin vueltas. ¡Te reconocemos tu antigüedad como cliente con Carga Virtual!',
  seacButton: 'Pedilo Ahora',
  seacStepTitle: '¿Cómo pido un préstamo?',
  seacStepText: 'Ingresando tu DNI, ya sabés si tenés un préstamo disponible. Después sacás las fotos, elegís como te pagamos y listo. Lo hicimos bien simple.',
  seacData: 'Datos',
  seacDataText: 'Ingresá tu DNI para saber cuánto te prestamos y mandanos una selfie.',
  seacValidation: 'Validación',
  seacValidationText: 'Por tu seguridad validamos tus datos.',
  seacAccreditation: 'Acreditación',
  seacAccreditationText: '¡Recibí el dinero en tu cuenta en minutos!',
  seacRates: '*En todos los casos, la Tasa Nominal Anual (TNA), la Tasa Efectiva Anual (TEA) y el Costo Financiero Total Nominal Anual (CFTNA) varían según el perfil crediticio del solicitante y plazo de financiación elegido. En todos los casos, la TNA, TEA y el CFT aplicable serán informados claramente al momento de ingresar la solicitud del adelanto o préstamo y antes de su otorgamiento.',
  seacValues: 'El período mínimo para la devolución de un préstamo es de 1 mes y el máximo 18 meses. La TNA de un préstamo varía dependiendo del perfil crediticio del solicitante, siendo la mínima 84% y la máxima 108%. Ejemplo de préstamo: Monto solicitado de $50.000 a 12 meses, TNA (sin IVA): 84%, TEA (sin IVA): 125,2%, CFTNA (con IVA): 168,9%, Cuota: $6.295,10 (+ IVA). Total a pagar: $75.541,19 (+ IVA). El monto mínimo de financiación es de $1.000 y el monto máximo de $50.000. El otorgamiento del préstamo está sujeto a evaluación crediticia. Tipo de Amortización Francés. Dependiendo del perfil de riesgo crediticio: TEA mínima 125,22% + IVA; TEA máxima 181,27% + IVA.',

  // Bind
  bindName: 'Grupo Bind',
  bindTitle: '¡Pedí tu Préstamo desde WhatsApp!',
  bindClic: 'Al hacer click se abrirá automáticamente tu whatsapp, para que nos hables.',
  bindService1: 'Grupo BIND te acerca préstamos en forma ágil y fácil a través de Credicuotas, una empresa de Grupo BIND.',
  bindService2: 'Acercate para mayor informacion a una de las sucursales disponibles o chateanos directamente por Whatsapp.',
  bindService3: 'Si lo querés de forma online ingresa a ',
  bindWebCredicuotas: 'www.credicuotas.com.ar ',
  bindWppBusiness: 'Whatsapp business, 100% validado',
  bindAvailable: 'Consulta tu disponible',
  bindStep: 'Pedilo chateando con un asesor, y coordiná retirarlo en la sucursal',
  bindWait: 'Te esperamos en estas sucursales:',
  bindSanPedro: 'San Pedro',
  bindSanPedroOffice: 'Pellegrini 650',
  bindEzeiza: 'Ezeiza',
  bindEzeizaOffice: 'French 172',
  bindVillaMitre: 'Villa Mitre',
  bindVillaMitreOffice: 'Garibaldi 299',
  bindBahiaBlanca: 'Bahia Blanca',
  bindBahiaBlancaOffice: 'San Martín 302',
  bindTandil: 'Tandil',
  bindTandilOffice: 'San Martín 626',
  bindSalta: 'Salta',
  bindSaltaOffice: '20 de Febrero 63',
  bindTucumanMicro: 'Tucumán Micro',
  bindTucumanMicroOffice: 'San Martín 670',
  bindTucumanCentro: 'Tucumán Centro',
  bindTucumanCentroOffice: 'San Martín 979',
  // Service
  serviceName: 'Pago de facturas',
  serviceTitle: 'Pagá tus facturas con Credicuotas',
  serviceSubtitle: '¿Necesitás pagar tus servicios? Pagá tus facturas con nosotros.',
  serviceButtonBanner: 'Pedilo Ahora',
  serviceStepTitle: '¿Cómo funciona?',
  serviceStepText: 'Por ser un cliente fidelizado de Credicuotas accedés a este beneficio de un préstamo para usarlo como pago del servicio o empresa y lo pagás a los 30 días.',
  serviceStepSubtitle: ' Es muy simple, mirá:',
  serviceCompany: 'Elegí la empresa o servicio a pagar.',
  serviceValidation: 'Validamos automáticamente tu identidad.',
  serviceFinished: 'Listo! Pagaste tu servicio.',
  serviceButtonCredit: 'Simulá tu crédito',
  serviceToCellphoneRecharge: 'También podés hacer una recarga de celular. ',

  // CellphoneRecharge
  rechargeName: 'Recarga de celular',
  rechargeTitle: '¡Ahora podés recargar tu celu!',
  rechargeSubtitle: '¿Te quedaste sin crédito? recargá tu celular con nosotros',
  rechargeButtonBanner: 'Pedilo Ahora',
  rechargeStepTitle: '¿Cómo funciona?',
  rechargeStepText: 'Por ser un cliente fidelizado de Credicuotas accedés a este beneficio de un préstamo para usarlo como recarga de crédito en cualquier celular y lo pagás a los 30 días.',
  rechargeStepSubtitle: 'Es muy simple, mirá:',
  rechargeNumberOne: '1',
  rechargeCompany: 'Elegí la compañia de telefono y el monto a recargar.',
  rechargeNumberTwo: '2',
  rechargeValidation: 'Validamos automáticamente tu identidad.',
  rechargeNumberThree: '3',
  rechargeFinished: 'Listo! Acreditamos al instante la recarga a tu celular.',
  rechargeButtonCredit: 'Simulá tu crédito',
  rechargeHiring: 'La contratación del producto está sujeto a evaluación crediticia por parte de Credicuotas y a tiempos de acreditación de la empresa de telefonía celular.',
  rechargeToService: 'También podés pagar tus facturas. ',

  // Sucursal
  branchesName: 'Sucursales Credicuotas',
  branchesTitle: '¡Pedí tu Préstamo desde WhatsApp!',
  branchesMoney: 'Hasta $ 300.000 en hasta 24 Cuotas',
  branchesClic: 'Al hacer click se abrirá automáticamente tu whatsapp, para que nos hables.',
  branchesButton: 'Comenzar',
  branchesWppBusiness: 'Whatsapp business, 100% validado',
  branchesAvailable: 'Consultá tu disponible.',
  branchesChat: 'Pedilo chateando con un asesor y coordiná retirarlo en la sucursal.',
  branchesWait: 'Te esperamos en San Martin 797, bajo estrictos protocolos para tu seguridad.',

  // Error 404
  errorTitle: 'Página no encontrada',
  errorSubtitle: 'Algo salió mal ¡Pero aún estás muy cerca de tu próximo préstamo personal!',
  errorButton: 'Ir a la página principal',

  // Dar de baja
  unsuscribeInformationBannerTitle: 'Dar de baja tu préstamo',
  unsuscribeInformationTitle: '¿Cómo doy de baja el préstamo que solicité?',
  unsuscribeInformationStep1: 'Ingresá a autogestión',
  unsuscribeInformationStep2: 'Buscá la opción de Cancelación anticipada',
  unsuscribeInformationStep3: 'Realizá el pago',
  unsuscribeInformationBackButton: 'IR A AUTOGESTIÓN',

  // Pay services
  payServicesTitle: 'Pagá tus servicios y recargá tu celular',
  payServicesSubtitleFirst: '¿Necesitás pagar tus servicios?',
  payServicesSubtitleSecond: 'Pagá tus facturas con nosotros.',
  payServicesWhatToPay: '¿Qué podés pagar?',
  payServicesAvailable: 'Más de 4000 servicios disponibles',
  payServicesPayments: 'Pagá en cuotas tus facturas de servicios',
  payServicesBenefits: 'Por ser cliente fiel de Credicuotas accedés al beneficio de pagar tus facturas en cuotas.',
  payServicesPayNow: 'Pagar ahora',
  payServicesRecharge: 'Recargá tu celular y pagalo en 30 días',
  payServicesRechargeSubtitle: 'Con Credicuotas podes recargar de forma inmediata.',
  payServicesRechargeNow: 'Recargar ahora',
  payServicesHowWorks: '¿Cómo funciona?',
  payServicesSimple: 'Es muy simple, mirá:',
  payServicesFirstItem: '1',
  payServicesSelectCompany: 'Elegí tu compañía de celular o el servicio a pagar.',
  payServicesSecondItem: '2',
  payServicesValidate: 'Validamos automáticamente tu identidad',
  payServicesThirdItem: '3',
  payServicesFinished: 'Listo! Recargaste o pagaste tu servicio.',
  payServicesWhyCC: '¿Por qué te conviene pagar con credicuotas?',
  payServicesWeAreSimple: 'SOMOS SIMPLES',
  payServicesFinancePayments: 'FINANCIAMOS TU PAGO EN CUOTAS FIJAS',
  payServicesMoreBenefits: 'PENSAMOS SIEMPRE EN DARTE MÁS BENEFICIOS',
  payServicesLight: 'Luz',
  payServicesWater: 'Agua',
  payServicesGas: 'Gas',
  payServicesCellphone: 'Recarga de celular',
  payServicesTv: 'TV',
  payServicesHealth: 'Prepaga',
  payServicesInsurance: 'Seguro',
  payServicesInternet: 'Internet',

  HubRenewers: {
    renewerCustomerTitle: 'Fidelizados',
    loyalCustomerTitle: 'Clientes',
    bannerTitle: 'Nos encanta que estés con nosotros',
    bannerDescription: 'Desde acá podrás acceder a los <b>servicios y beneficios exclusivos</b> para vos.',
    howContinue: '¿Cómo querés continuar?',
    onboardingCard: [
      {
        title: 'Pedí tu préstamo',
        description: 'Utilizá tu disponible cuando quieras y lo necesites, con las cuotas más bajas.',
        ctaLabel: 'Solicitar ahora',
      },
      {
        title: 'Pagá tus servicios',
        description: 'Financiá tus gastos del mes en cuotas fijas.',
        ctaLabel: 'Pagar ahora',
      },
      {
        title: 'Recargá tu celular',
        description: 'Mantenete conectado en todo momento y págalo el próximo mes.',
        ctaLabel: 'Recargar ahora',
      },
    ],
    benefit: '¿Vas a dejar pasar todas estas ventajas?',
    benefitDescription: [
      'Cuotas más bajas para vos.',
      'Pagás tu primer cuota recién a los 60 días.',
      '¡Pagá tus facturas hasta en 3 cuotas!',
      'Descuentos en miles de comercios.',
      'Regalos sorpresas',
      'Y próximamente más...',
    ],
    finalBannerTitle: 'Un apoyo financiero constante',
    finalBannerDescription: 'Queremos seguir cerca tuyo en cada uno de tus desafios',
  },

  Contracts: {
    bannerTitle: 'Contratos de adhesión<br/>Ley 24.240 de Defensa del Consumidor',
    contract1: 'Contrato de préstamo personal',
    contract2: 'Contrato de préstamo personal otorgado en la sucursal de Tucumán',
    contract3: 'Contrato de préstamo personal para compras en comercios adheridos',
    ctaLabel: 'Descargar',
  },

  NormativeBanner: {
    message: 'ACYMA C/ CREDICUOTAS CONSUMO S.A.',
    ctaRead: 'LEER',
    dialogTitle: 'ACYMA c/ CREDICUOTAS CONSUMO S.A.',
    dialogMessage: `
    Por medio de la presente se hace saber la existencia del siguiente juicio “ASOCIACION CIVIL POR LOS CONSUMIDORES Y EL MEDIO AMBIENTE (ACYMA) C/ CREDICUOTAS CONSUMO S.A Y OTRO s/ ordinario” (Expediente Nro. 14356/2020), en trámite por ante el Juzgado nacional de 1° instancia en lo comercial n° 15 secretaria n° 29 de la jurisdicción de Capital Federal, sito en Callao 635 piso 3° CABA. El objeto del presente es: "De conformidad con lo establecido en el art. 1388 CCCN se disponga la devolución de toda suma pagada —con más sus intereses— por los consumidores en concepto de cargo “Servicio Online” o cualquiera sea la denominación utilizada por la demandada (Ej: “gastos de otorgamiento”, “gasto de gestión online”, etc); se ordene a CREDICUOTAS el cese de cobros injustificados y sin contraprestación alguna a sus clientes; se imponga la sanción ejemplificadora de daño punitivo prevista para situaciones de perjuicios masivos y con grave afectación de los derechos de los consumidores y usuarios como ocurre en el presente caso (LDC:52 bis). El valor de la sanción a favor de cada consumidor será dos veces el monto que deba reintegrarse en concepto del cobro ilegal del gasto “Servicio Online” y/o similar con más sus intereses; se ordene que los funcionarios encargados de la demandada de las áreas de contabilidad, finanzas, ventas, comercial, marketing y de atención al cliente realicen cursos en materia de derechos de los usuarios y consumidores en organismos públicos y/o privados a fin de minimizar las posibilidades de que se repita en el futuro una conducta como la aquí denunciada; se disponga la publicación de la sentencia de condena que se dicte en el caso en todas las sucursales de la demandada y en su página de Internet, de manera visible y destacada, sin perjuicio de su publicación en los medios de comunicación que V.S. estime pertinentes, con cargo en­ la demandada".
    <br /><br />
    Se hace saber a los clientes o exclientes involucrados que podrán hacer uso de su derecho de ser excluidos de los efectos de la sentencia que se dicte, indicándose que este derecho podrá usarse por ejemplo en el caso que pretendan iniciar una demanda individual. Y en caso de optarse por ello deberán presentar nota simple ante el domicilio de cualquiera de las partes sin necesidad de firma letrada dentro de los sesenta días (60) de colocados los avisos. A tal fin denunciamos los domicilios constituidos de las partes, Asociación Civil por los Consumidores y Medio Ambiente (ACYMA) con domicilio en la calle Libertad 434, Piso 6 of. “63”, CABA y Credicuotas Consumo S.A. con domicilio Paraná 426, Piso 18, of. “E”, CABA”. Su silencio será considerado como manifestación de voluntad de ser incluidos en los efectos de la sentencia que se dicte. Buenos Aires, 08 de marzo de 2024.-CA.
    <br /><br />
    ANA PAULA FERRARA SECRETARIA
    `,
  },
};

export default i18n;
